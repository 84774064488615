import forList from "@/mixin/forList";
var numeral = require('numeral');


export default {
    name: "salesData",
    noFetchFirst: true,
    mixins: [forList],
    data() {
        return {
            SP_: {

                searchRegionId: null,
                searchStartTime: "",
                searchEndTime: "",
            },
            regionList: []
        };
    },

    methods: {

        renderHeader(h, { column }) {
            return h('span', {}, [
                h('span', {}, column.label.split('/')[0]),
                h('br'),
                h('span', {}, column.label.split('/')[1])
            ])
        },
        /**
         * 金额转换成又逗号分割格式
         * @Author zhuiyi
         * @paramsource数据源
         * @authorzhuiyi 2023/01/04
         */
        NUMERAL(num, flag) {
            if (!num) {
                return '-'
            } else {


                if ((num + '').indexOf(".") != -1 && flag) {
                    return numeral(num).format('0,0.0')
                } else if ((num + '').indexOf(".") != -1 && !flag) {
                    return numeral(num).format('0,0.00')
                } else {
                    return numeral(num).format('0,0')
                }
            }
        },

        async fetchData() {
            const loading = this.$loading();
            try {
                const res = await this.$axios({
                    url: "/api/orderStatistics/queryOrderSaleStatistics",
                    method: "post",
                    data: this.SP_
                });

                if (res.code === 2000) {

                    const list = res.data.orderSaleStatisticsList;
                    this.tableData = list;
                    loading.close();
                } else if (res.code !== 1003)
                    throw res

            } catch (reason) {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });

                loading.close();
            }
        },
        async InitDate() { //默认统计本周的数据（周一到周天）
            // var today = new Date();
            // var year = today.getFullYear();
            // this.SP_.searchStartTime=year+"-01-01";
            // this.SP_.searchEndTime=year+"-12-31";

            const today = new Date(); // 获取今天的日期

            // getDay() 返回0（周日）到6（周六），我们要找的是本周一
            let dayOfWeek = today.getDay();
            console.log(dayOfWeek);

            // 本周一距离今天有几天，如果今天是周一，则为0天
            let daysToMonday = (dayOfWeek === 0) ? 6 : dayOfWeek - 1;

            // 创建一个新的Date对象表示本周一的日期
            let mondayThisWeek = new Date(today);
            mondayThisWeek.setDate(mondayThisWeek.getDate() - daysToMonday);

            // 本周日是本周六之后的第二天，所以我们先找到本周六再往后加一天即可得到本周日
            let sundayThisWeek = new Date(mondayThisWeek);
            sundayThisWeek.setDate(sundayThisWeek.getDate() + 6); // 加上六天到达周六，然后再加一天到达周日

            function formatDate(date) {
                // 使用Date对象的toISOString方法来获取ISO标准的日期字符串，并去除时间部分，
                // 最后替换'-'字符使其符合 YYYY-MM-DD 的格式。
                return date.toISOString()
                    .split('T')[0] // 分割并取日期部分 "YYYY-MM-DD"
                    .replace(/-/g, '-'); // 第二次替换保证月份和日份有前导零如果它们是个位数
            }

            console.log('本周星期一是：', formatDate(mondayThisWeek));
            console.log('本周星期天是：', formatDate(sundayThisWeek));

            this.SP_.searchStartTime = formatDate(mondayThisWeek);
            this.SP_.searchEndTime = formatDate(sundayThisWeek);


        },
        async queryRegion() {

            try {
                const res = await this.$axios({
                    url: "/api/configuration/queryAllOrg",
                    method: "GET"
                });

                if (res.code === 2000) {

                    const regiondata = res.data.regionList;
                    this.regionList = regiondata;

                } else if (res.code !== 1003)
                    throw res

            } catch (reason) {
                console.warn("获取区域数据失败", reason);

            }
        },

        // 点击导出
        async handleExportBtn() {
            let loadingRef;
            try {
                await this.$confirm("确定导出吗", "系统提示")
                loadingRef = this.$loading();
                const res = await this.$axios({
                    url: "/api/orderStatistics/downOrderSaleStatistics",
                    method: "post",
                    data: this.SP_
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "已加入导出任务，去消息管理的下载通知中查看吧",
                        type: "success"
                    })
                    loadingRef && loadingRef.close();

                } else if (res.code !== 1003)
                    throw res;

            } catch (reason) {
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "导出失败",
                        type: "error"
                    });
                }
                loadingRef && loadingRef.close();
            }
        }
    },

    created() {

        this.queryRegion();
        this.InitDate();
        this.fetchData()
        this.auth.export = this.$hasAuthFor("api/orderStatistics/downOrderSaleStatistics");
    }


};